<template>
    <div>
        <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
            <!-- Info: Input Fields -->
            <b-row v-for="(item, index) in stocks"  :id="stocks.id" :key="stocks.index" ref="row">
                <b-col cols="10" md="10">
                    <b-row>
                        <b-col cols="12" md="4">
                            <!-- stock code -->
                            <b-form-group :label="$t('stock.code')" label-for="code">
                                <country-select :country-id.sync="item.code" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                            <!-- barcode -->
                            <b-form-group :label="$t('stock.barcode')" label-for="barcode">
                                <country-select :country-id.sync="item.barcode" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                            <!-- unit -->
                            <b-form-group :label="$t('stock.unit')" label-for="unit">
                                <country-select :country-id.sync="item.unit" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <!-- stock name -->
                            <b-form-group :label="$t('stock.name')" label-for="name">
                                <country-select :country-id.sync="item.name" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                            <!-- priceType -->
                            <b-form-group :label="$t('stock.pricetype')" label-for="priceType">
                                <country-select :country-id.sync="item.priceType" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <!-- kdv -->
                            <b-form-group :label="$t('stock.kdvdahil')" label-for="kdvDahil">
                                <b-form-checkbox v-model="item.kdvDahil" class="custom-control-primary" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <!-- totalKdvDahil -->
                            <b-form-group :label="$t('stock.totalkdvdahil')" label-for="totalKdvDahil">
                                <b-form-checkbox v-model="item.totalKdvDahil" class="custom-control-info" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="2" class="mb-50 mt-md-1">
                    <b-button variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>{{$t('delete')}}</span>
                    </b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
            </b-row>
        </b-form>
        <b-button variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>{{$t('addnew')}}</span>
        </b-button>
    </div>
</template>

<script>
    import {
        BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormDatepicker, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, alphaNum, email } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import vSelect from 'vue-select'
    import useList from '../list/useList'
    import CountrySelect from '../../country/components/Select.vue';


    const initData = {
        code: null,
        name: null,
        barcode: null,
        unit: null,
        priceType: null,
        kdvDahil: null,
        totalKdvDahil: null
    };

    export default {
        components: {
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BTable,
            BCard,
            BCardHeader,
            BCardTitle,
            BFormCheckbox,
            BFormDatepicker,
            vSelect,
            CountrySelect,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            BFormInvalidFeedback,
        },
        props: {
            stocks: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                required,
                alphaNum,
                email,
                nextTodoId: 2,
            }
        },
        setup(props) {
           
            if (props.stocks.length === 0) {
                props.stocks.push({ ...initData });
            }
            const { statusOptions } = useList();
            const resetData = () => {
                this.stocks.value = [{ ...initData }];
            }
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)

            return {
                statusOptions,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
        methods: {
            repeateAgain() {
                this.stocks.push({ ...initData });
            },
            removeItem(index) {
                this.stocks.splice(index, 1)
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
