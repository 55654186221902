import axios from '@axios'

export const APP_STORE_MODULE_NAME = 'apps-stock';
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAllData(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Countries/getall`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchData(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Countries/getbyid?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addNewData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/Countries/add', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/Countries/update', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        suspend(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/Countries/suspend?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
