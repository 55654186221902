<template>
    <!-- Info: Input Fields -->
    <b-row>
        <b-col cols="12" md="12">
            <!-- Deal Name -->
            <validation-provider #default="validationContext" name="dealname" rules="required">
                <b-form-group :label="$t('stock.dealname')" label-for="dealname">
                    <b-form-input autofocus trim
                                  id="dealname"
                                  v-model="modelData.dealname"
                                  :state="getValidationState(validationContext)"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :placeholder="$t('stock.dealname')" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
        </b-col>

        <b-col cols="12" md="12">
            <!-- Customer Name -->
            <b-form-group :label="$t('customer.name')" label-for="customerId">
                <country-select :country-id.sync="modelData.customerId" />
            </b-form-group>
        </b-col>

        <b-col cols="12" md="3">
            <!-- Start Date -->
            <validation-provider #default="validationContext" name="startdate" rules="required">
                <b-form-group :label="$t('stock.startdate')" label-for="startdate">
                    <b-form-datepicker id="startdate"
                                       hide-header
                                       v-model="modelData.startdate"
                                       :state="getValidationState(validationContext)"
                                       :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                       :placeholder="$t('stock.startdate')" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
        </b-col>

        <b-col cols="12" md="3">
            <!-- End Date -->
            <validation-provider #default="validationContext" name="enddate" rules="required">
                <b-form-group :label="$t('stock.enddate')" label-for="enddate">
                    <b-form-datepicker id="enddate"
                                       hide-header
                                       v-model="modelData.enddate"
                                       :state="getValidationState(validationContext)"
                                       :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                       :placeholder="$t('stock.enddate')" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
        </b-col>

        <!-- Field: priceType -->
        <b-col cols="12" md="3">
            <b-form-group :label="$t('stock.pricetype')" label-for="pricetype">
                <v-select v-model="modelData.priceType"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="priceType"
                          :placeholder="$t('stock.pricetype')" />
            </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="3" v-if="modelData.id">
            <b-form-group :label="$t('stock.status')"
                          label-for="status">
                <v-select v-model="modelData.status"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="status"
                          :placeholder="$t('stock.status')" />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
    import {
        BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BFormDatepicker, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, alphaNum, email } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import vSelect from 'vue-select'
    import useList from '../list/useList'
    import CountrySelect from '../../country/components/Select.vue';

    export default {
        components: {
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BTable,
            BCard,
            BCardHeader,
            BCardTitle,
            BFormCheckbox,
            BFormDatepicker,
            vSelect,
            CountrySelect,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            BFormInvalidFeedback,
        },
        props: {
            modelData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                required,
                alphaNum,
                email,
            }
        },
        setup(props) {

            const { statusOptions } = useList();
            const resetData = () => {
                this.modelData.value = {};
            }
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)

            return {
                statusOptions,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
