<template>
    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="options"
              @input="(item)=>$emit('update:countryId', item.value)"
              :clearable="false"
              :placeholder="$t('country.name')"
              v-model="selectedItem"
              label="title" />
</template>
<script>
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import storeModule, { APP_STORE_MODULE_NAME } from '../storeModule'

    export default {
        components: {
            vSelect
        },
        props: {
            countryId: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
            }
        },
        setup(props) {
            const options = ref([]);
            const selectedItem = ref({});
            // Register module
            if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
            })

            store.dispatch(`${APP_STORE_MODULE_NAME}/fetchAllData`)
                .then(response => {
                    options.value = response.data.map(f => ({ value: f.id, title: f.name }));
                    selectedItem.value = options.value.find(f => f.value === props.countryId);
                })
                .catch(error => { })
            return {
                options,
                selectedItem,
                APP_STORE_MODULE_NAME,
            }
        }, methods: {
            onSelect(val) {
                console.log(val);
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
