<template>
    <component :is="modelData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="modelData === undefined">
            <h4 class="alert-heading">
                {{$t('errorfetchingdata')}}
            </h4>
            <div class="alert-body">
                {{$t('nofoundwiththisid')}}
                <b-link class="alert-link" :to="{ name: `${APP_STORE_MODULE_NAME}-list` }">
                    {{$t('checklist')}}
                </b-link>
                {{$t('forotherrecords')}}

            </div>
        </b-alert>
        <!-- Form -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form class="p-2" @submit.prevent="handleSubmit(updateModel)" @reset.prevent="resetForm">
                <b-tabs v-if="modelData" pills>
                    <!-- Tab: Edit -->
                    <b-tab active>
                        <template #title>
                            <feather-icon icon="LayersIcon" size="16" class="mr-0 mr-sm-50" />
                            <span class="d-none d-sm-inline">{{$t('stock.generalinformation')}}</span>
                        </template>
                        <edit-tab-model :model-data="modelData" class="mt-2 pt-75" />
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <feather-icon icon="PlusIcon" size="16" class="mr-0 mr-sm-50" />
                            <span class="d-none d-sm-inline">{{$t('stock.add')}}</span>
                        </template>
                        <edit-tab-stock :stocks="modelData.stocks" class="mt-2 pt-75" />
                    </b-tab>
                </b-tabs>

                <!-- Action Buttons -->
                <b-button variant="primary"
                          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-1"
                          type="submit">
                    {{ $t('Save') }}
                </b-button>
                <b-button class="mt-1"
                          variant="outline-secondary"
                          @click="goList()">
                    {{ $t('close') }}
                </b-button>
            </b-form>
        </validation-observer>
    </component>
</template>

<script>
    import {
        BButton, BForm, BTab, BTabs, BCard, BAlert, BLink,
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { ref, onUnmounted } from '@vue/composition-api'
    import router from '@/router'
    import store from '@/store'
    import storeModule, { APP_STORE_MODULE_NAME } from '../storeModule'
    import EditTabModel from './EditTabModel.vue';
    import EditTabStock from './EditTabStock.vue';

    export default {
        components: {
            BForm,
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
            BButton,
            EditTabModel,
            EditTabStock,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
        },
        setup() {
            const modelData = ref(null);

            // Register module
            if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
            })

            if (router.currentRoute.params.id) {
                store.dispatch(`${APP_STORE_MODULE_NAME}/fetchData`, { id: router.currentRoute.params.id })
                    .then(response => { modelData.value = response.data })
                    .catch(error => {
                        if (error.response.status === 404) {
                            modelData.value = undefined
                        }
                    })
            } else {
                //new item
                modelData.value = { stocks: [] };
            }

            return {
                modelData,
                APP_STORE_MODULE_NAME,
            }
        },
        methods: {
            goList() {
                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-list` })
            },
            updateModel() {
                const { id } = router.currentRoute.params;
                let method;
                if (id) {
                    method = `${APP_STORE_MODULE_NAME}/updateData`;
                } else {
                    method = `${APP_STORE_MODULE_NAME}/addNewData`;
                }
                store.dispatch(method, { id, ...this.modelData })
                    .then(response => {
                        this.$bvToast.toast(`${this.$t("processesissuccesful")} `, {
                            title: `${this.$t("success")} `,
                            variant: "success",
                            solid: true,
                        });
                        if (!id) {
                            if (response.data.id) {
                                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-edit`, params: { id: response.data.id } });
                            } else {
                                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-list` });
                            }
                        } else {
                            this.modelData.value = response.data;
                        }
                    })
                    .catch(error => {
                        const h = this.$createElement
                        let vNodesMsg = [];
                        const errors = error.response?.data.errors;
                        if (errors) {
                            Object.keys(errors).forEach(key => {
                                vNodesMsg.push(h('strong', [
                                    h('div', { class: 'mr-2' }, `${key} : ${errors[key][0]}`),
                                ]));
                            });
                        } else {
                            vNodesMsg.push(h('div', [
                                h('strong', { class: 'mr-2' }, error),
                            ]));
                        }
                        this.$bvToast.toast([vNodesMsg], {
                            title: `${this.$t("error")} `,
                            variant: "danger",
                            solid: true,
                        });
                    })
            },
        }
    }
</script>

<style>
</style>
